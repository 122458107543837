






















import { Component, Vue, Watch } from 'vue-property-decorator';
import { PortalTarget } from '../domain/PortalTarget';

@Component
export default class PortalTypeSelection extends Vue {
    private target: PortalTarget = PortalTarget.NEOTV;

    @Watch('target')
    private updateTarget() {
        this.$emit('updateTarget', this.target);
    }
}
