


















import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import Field from '@/components/Field.vue';
import PortalTypeSelection from './PortalTypeSelection.vue';
import { PortalTarget } from '../domain/PortalTarget';
import Card from '@/components/card/Card.vue';

export interface PortalFormEvent {
    name: string;
    target: PortalTarget;
}

@Component({
    components: { Field, PortalTypeSelection, Card },
})
export default class PortalForm extends Vue {
    @Prop() private hasSubmitted!: boolean;
    @Prop() private editMode!: boolean;
    @Prop() private givenPortalName!: string;
    @Prop() private givenTarget!: PortalTarget;
    private target: PortalTarget = PortalTarget.NEOTV;
    private name: string = '';

    @Watch('givenTarget')
    private onParentGivenTargetUpdate() {
        this.target = this.givenTarget;
    }

    @Watch('givenPortalName')
    private onParentPortalNameUpdate() {
        this.name = this.givenPortalName;
    }

    @Watch('name')
    private onNameUpdate() {
        this.$emit('updateName', this.name);
    }

    private onTargetUpdate(target: PortalTarget) {
        this.target = target;
        this.$emit('updateTarget', target);
    }

    get targetIsNeoTv() {
        return this.target === PortalTarget.NEOTV;
    }

    get hasName() {
        return Boolean(this.name);
    }

    get submitButtonType() {
        return !Boolean(this.givenPortalName) ? 'success' : 'warning';
    }

    get submitButtonText() {
        return !Boolean(this.givenPortalName) ? 'Créer le portail' : 'Modifier le portail';
    }
    private submit() {
        this.$emit('submit', this.target, this.name);
  }
}
